<template>
  <label class="graphics-loader">
    Graphics:
    <div>
      <input
        type="file"
        accept="image/*, application/pdf, application/postscript"
        multiple="true"
        @change="addFiles"
        ref="input"
      />
    </div>
  </label>
</template>

<script>
export default {
  computed: {
    graphics: {
      get() {
        return this.$store.getters.graphics;
      }
    }
  },

  methods: {
    addFiles(event) {
      for (const file of event.target.files) {
        this.$store.commit("addGraphic", {
          name: file.name,
          file: file
        });
      }
    }
  }
};
</script>

<style lang="less" scoped></style>
