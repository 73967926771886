<template>
  <label for="color">
    Product Color:
    <input
      placeholder="Black, White, Forest Green, etc."
      v-model.lazy="color"
    />
  </label>
</template>

<script>
export default {
  data() {
    return {
      options: {
        black: "#000",
        white: "#fff",
        red: "#f00",
        green: "#0f0",
        blue: "#00f"
      },

      showCustom: false
    };
  },

  computed: {
    color: {
      get() {
        return this.$store.getters.color;
      },
      set(value) {
        this.$store.commit("setColor", value);
      }
    }
  }
};
</script>

<style lang="less" scoped></style>
