import Vue from "vue";
import App from "./App.vue";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");

(async () => {
  if (!("PointerEvent" in window)) {
    await import("@wessberg/pointer-events");
  }
})();
