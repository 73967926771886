<template>
  <div class="modal-wrapper">
    <div class="modal">
      <p>
        This editor allows you to upload multiple design files to the front
        and/or back of apparel templates. You can flip the apparel and adjust
        designs within the editor.
      </p>

      <p>
        Results will not be exact, but will help us get started and we will work
        with you to perfect your design.
      </p>

      <p>
        It is also not representive of all of the work we can do. If you can't
        reproduce the exact design you're looking for, please contact us, and
        we'll work with you to make your design happen.
      </p>
      <button @click="close">I Understand</button>
    </div>
  </div>
</template>

<style lang="less" scoped>
.modal {
  display: flex;
  flex-direction: column;
}

p {
  margin-bottom: 10px;
}

button {
  margin-left: auto;
}
</style>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
