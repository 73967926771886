export default {
  serverUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://ud6is30a44.execute-api.us-east-1.amazonaws.com/dev/",
  email:
    process.env.NODE_ENV === "development"
      ? "galan.c.m@gmail.com"
      : "contact@residencyapparel.com"
};
