<template>
  <div id="app">
    <transition name="message" appear>
      <div
        class="error"
        v-if="this.errorMessage !== null"
        v-html="this.errorMessage"
      ></div>
    </transition>
    <div class="spinner" v-if="showSpinner">
      <img src="./assets/icons/spinner-large.svg" />
    </div>
    <div class="designer">
      <Editor></Editor>
      <Sidebar
        @request="showSubmit = true"
        :showRequestButton="showRequestButton"
      ></Sidebar>
    </div>
    <Submit v-if="showSubmit" @close="showSubmit = false"></Submit>
    <Notice v-show="showNotice" @close="showNotice = false"></Notice>
  </div>
</template>

<style lang="less">
@import "./assets/less/base.less";
</style>

<style lang="less" scoped>
.designer {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media (max-width: 629px) {
    flex-direction: column;
  }

  .editor {
    height: 60%;

    @media (min-width: 630px) {
      width: 70%;
      height: 100%;
    }
  }
  .sidebar {
    background-color: #f4f3ef;
    height: 40%;

    @media (min-width: 630px) {
      width: 30%;
      height: auto;
    }
  }
}

.error {
  position: absolute;
  top: -12px;
  width: 100vw;
  border-bottom: 5px solid #f4f3ef;
  z-index: 100;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  padding: 20px 0 7px;
  color: white;
  background-color: #d11;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.spinner {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  pointer-events: none;

  img {
    margin: auto;
    animation: 3s spinner linear infinite;
  }
}

.message-enter-active, // transition override
.message-leave-active {
  transition-delay: 1s;
}
</style>

<script>
const Editor = () => import("@/components/Editor.vue");
import Sidebar from "@/components/Sidebar.vue";
const Submit = () => import("@/components/Submit.vue");
import Notice from "@/components/Notice.vue";

import axios from "axios";

import config from "@/config";
import { Promise } from "q";

export default {
  name: "Designer",
  components: {
    Editor: Editor,
    Sidebar,
    Submit: Submit,
    Notice
  },

  data() {
    return {
      showNotice: false,
      showSubmit: false,
      showRequestButton: true,
      errorMessage: null,
      showSpinner: false
    };
  },

  async mounted() {
    // load order if given
    if (location.pathname !== "/" || location.hash !== "") {
      const orderRequestId =
        location.pathname !== "/"
          ? location.pathname.slice(1)
          : location.hash.slice(2);
      this.showSpinner = true;

      axios
        .get(`${config.serverUrl}/get-order/${orderRequestId}`)
        .then(async response => {
          const order = response.data;

          // load images from Storage
          const imageLoadPromises = [];
          for (const graphic of order.request.graphics) {
            const promise = axios.get(graphic.downloadUrl, {
              responseType: "blob"
            });
            promise.then(response => {
              graphic.file = response.data;
            });
            imageLoadPromises.push(promise);
          }

          await Promise.all(imageLoadPromises);

          this.$store.commit("setGraphics", order.request.graphics);
          this.$store.commit("setApparel", order.request.apparel);
          this.$store.commit("setColor", order.request.color);
          this.$store.commit("setFabric", order.request.fabric);
          this.$store.commit("setNotes", order.request.notes);
          this.$store.commit("setQuantity", order.request.quantity);
          this.showRequestButton = false;
        })
        .catch(error => {
          if (error.message === "Network Error") {
            this.errorMessage =
              "Could not connect to server.<br>Try again later, or contact the customer directly.";
          } else if (error.response.status === 404) {
            this.errorMessage = `Unable to find order request <em>${orderRequestId}</em>.<br>Try double-checking in the database, or the contact customer directly.`;
          } else {
            this.errorMessage = `Unknown Error: ${error.response.status}`;
          }
        })
        .finally(() => {
          this.showSpinner = false;
        });
    } else {
      this.showNotice = true;
    }
  }
};
</script>
