import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  graphics: [],
  color: "",
  apparel: "tShirt",
  fabric: "",
  quantity: 48,
  notes: ""
};

const getters = {
  graphics() {
    return state.graphics;
  },
  color() {
    return state.color;
  },
  apparel() {
    return state.apparel;
  },
  fabric() {
    return state.fabric;
  },
  quantity() {
    return state.quantity;
  },
  notes() {
    return state.notes;
  }
};

const mutations = {
  setGraphics(store, graphics) {
    store.graphics = graphics;
  },

  addGraphic(store, newGraphic) {
    const names = store.graphics.map(graphic => graphic.name);
    let name = newGraphic.name.split(".")[0];

    while (names.indexOf(name) !== -1) {
      if (name.search(/[0-9]$/) !== -1) {
        name = name.replace(/[0-9]$/, str => parseInt(str) + 1);
      } else {
        name += "1";
      }
    }

    store.graphics.push({
      file: newGraphic.file,
      fileName: newGraphic.name,
      name: name,
      side: "front",
      transform: { translation: [0, 0], rotation: 0, scale: 1 }
    });
  },

  setNewGraphicSide(store, side) {
    store.graphics[state.graphics.length - 1].side = side;
  },

  removeGraphic(store, target) {
    store.graphics = store.graphics.filter(g => g.name !== target.name);
  },
  transformGraphic(store, { target, transform }) {
    const storeGraphic = store.graphics.find(g => g.name === target.name);
    storeGraphic.transform = { ...storeGraphic.transform, ...transform };
  },
  flipGraphicSide(store, target) {
    const storeGraphic = store.graphics.find(g => g.name === target.name);
    storeGraphic.side = storeGraphic.side === "front" ? "back" : "front";
  },
  setColor(store, newColor) {
    store.color = newColor;
  },
  setApparel(store, newApparel) {
    store.apparel = newApparel;
  },
  setFabric(store, newFabric) {
    store.fabric = newFabric;
  },
  setQuantity(store, newQuantity) {
    store.quantity = newQuantity;
  },
  setNotes(store, newNotes) {
    store.notes = newNotes;
  }
};

export default new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: {},
  strict: true
});
