<template>
  <label class="apparel-selector">
    Product:
    <main class="apparel-list">
      <article v-for="(details, name) in options" :key="name">
        <img
          :src="details.image"
          @click="apparel = name"
          :class="apparel === name ? 'selected' : ''"
        />
        <div class="name">{{ name | legibleName }}</div>
        <span v-if="apparel === name" class="check">✓</span>
      </article>
    </main>
    <transition name="collapse">
      <section class="custom-message" v-show="apparel === 'custom'">
        <p>
          Please upload your graphic(s) and specify what type of
          apparel/merchandise you are interested in making below, and we will
          work out the details via email!
        </p>
      </section>
    </transition>
  </label>
</template>

<script>
export default {
  data() {
    return {
      options: {
        tShirt: {
          image: require("@/assets/apparel_templates/tshirt-front.svg")
        },
        polo: {
          image: require("@/assets/apparel_templates/polo-front.svg")
        },
        lsShirt: {
          image: require("@/assets/apparel_templates/lsShirt-front.svg")
        },
        hoodie: {
          image: require("@/assets/apparel_templates/hoodie-front.svg")
        },
        crewneck: {
          image: require("@/assets/apparel_templates/crewneck-front.svg")
        },
        hat: {
          image: require("@/assets/apparel_templates/hat-front.svg")
        },
        tote: {
          image: require("@/assets/apparel_templates/tote.svg")
        },
        custom: {
          image: require("@/assets/apparel_templates/other-icon.svg")
        }
      }
    };
  },

  computed: {
    apparel: {
      get() {
        return this.$store.getters.apparel;
      },
      set(value) {
        this.$store.commit("setApparel", value);
      }
    }
  },

  filters: {
    legibleName(string) {
      switch (string) {
        case "tShirt":
          return "T-Shirt";
          break;

        case "lsShirt":
          return "Long Sleeve Tee";
          break;

        case "crewneck":
          return "Sweatshirt";
          break;

        default:
          return string.replace(/(?:^|\s)\S/g, function(a) {
            return a.toUpperCase();
          });
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.apparel-selector {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}
.apparel-list {
  display: flex;
  flex-wrap: wrap;

  article {
    position: relative;
    width: 80px;
    margin: 10px;
  }
}
img {
  padding: 3px;
  width: 100%;
  object-fit: contain;
  box-sizing: content-box;
  border-radius: 4px 4px 0 0;
  border: 3px solid transparent;
  background-color: #889;
  cursor: pointer;

  &.selected {
    border-color: #003fd4;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2);
  }
}
.name {
  width: 100%;
  margin-top: -3px;
  border-radius: 0 0 4px 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  font-size: 13px;
  padding: 2px 5px;
}
.check {
  position: absolute;
  top: 70px;
  right: -19px;
  font-size: 30px;
  color: hsl(357.4, 80%, 28%);
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
}

.custom-message {
  margin: 5px;
  padding: 8px;
  line-height: 1.4;
  background-color: rgba(200, 200, 200, 0.5);
}

// TRANISTIONS
.collapse-enter-active,
.collapse-leave-active {
  transition: opacity 0.4s ease-out, clip-path 0.3s ease-out;
}
.collapse-enter,
.collapse-leave-to {
  opacity: 0;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}
.collapse-enter-to,
.collapse-leave {
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
</style>
