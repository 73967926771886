<template>
  <form class="sidebar">
    <ApparelSelector></ApparelSelector>
    <ColorPicker></ColorPicker>
    <GraphicsLoader></GraphicsLoader>
    <label class="fabric">
      Fabric:
      <input
        type="text"
        placeholder="If applicable (cotton, tri-blend, etc.)"
        v-model.lazy="fabric"
      />
    </label>
    <label for="quantity" class="quantity">
      Quantity:
      <span class="note">48 piece minimum</span>
      <input type="number" v-model.lazy="quantity" min="48" id="quantity" />
    </label>
    <label class="special-requests">
      Special Requests:
      <textarea v-model.lazy="notes"></textarea>
    </label>
    <button
      @click.prevent="request"
      v-if="showRequestButton"
      id="request-button"
    >
      Request Quote
    </button>
    <div class="padding-block">
      Firefox workaround
    </div>
  </form>
</template>

<script>
import ApparelSelector from "@/components/ApparelSelector.vue";
import ColorPicker from "@/components/ColorPicker.vue";
import GraphicsLoader from "@/components/GraphicsLoader.vue";

export default {
  props: {
    showRequestButton: Boolean
  },
  components: {
    ApparelSelector,
    ColorPicker,
    GraphicsLoader
  },
  computed: {
    fabric: {
      get() {
        return this.$store.getters.fabric;
      },
      set(value) {
        this.$store.commit("setFabric", value);
      }
    },
    quantity: {
      get() {
        return this.$store.getters.quantity;
      },
      set(value) {
        this.$store.commit("setQuantity", value);
      }
    },
    notes: {
      get() {
        return this.$store.getters.notes;
      },
      set(value) {
        this.$store.commit("setNotes", value);
      }
    }
  },

  methods: {
    request() {
      this.$emit("request");
    }
  }
};
</script>

<style lang="less" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  min-width: 215px;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: scroll;

  > *:not(:first-child) {
    margin-top: 30px;

    @media (max-width: 1210px) {
      margin-top: 15px;
    }
  }

  .quantity {
    line-height: 1.2;
    grid-template-columns: max-content auto;
    grid-template-rows: min-content min-content;

    @media (max-width: 1210px) {
      grid-template-columns: min-content auto;
    }

    .note {
      grid-row: 2;
      color: #0000008f;
      font-size: smaller;
      text-align: right;

      @media (max-width: 1210px) {
        grid-column: 2;
        grid-row: 1;
      }
    }

    input {
      grid-row: ~"1/3";

      @media (max-width: 1210px) {
        grid-column: ~"1 / 3";
        grid-row: 2;
        width: calc(100% - 20px);
      }
    }
  }

  .special-requests {
    display: block;
    align-items: start;
    margin-bottom: 40px;

    textarea {
      width: calc(100% - 2em);
      height: 11ex;
      resize: none;
      margin: 10px 1em 0 1em;
    }
  }

  #request-button {
    margin: auto 40px 0;
    padding: 15px;
    border-radius: 4px;
    font-size: 25px;
  }
  .padding-block {
    margin: 0;
    height: 20px;
    user-select: none;
    opacity: 0;
  }
}

::v-deep {
  label {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    font-weight: 600;

    @media (max-width: 1210px) {
      grid-template-columns: auto;
      grid-gap: 5px;
    }

    * {
      font-weight: normal;
    }
  }
  input,
  textarea {
    margin-left: 1em;
    width: auto;
  }
}
</style>
